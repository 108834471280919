
export const updateSearchSuggestions = (input, propertyOptions, setSearchSuggestions) => {

  
  if (input.trim() === '') {
    setSearchSuggestions([]);
  } else {
    const matchingBrandOptions = propertyOptions
      .filter(({ key }) => key === 'Brand' || 'Model')
      .flatMap(({ options }) =>
        options.filter((option) => option.toLowerCase().includes(input.toLowerCase()))
      )
      

    const matchingOtherOptions = propertyOptions
      .filter(({ key }) => key !== 'Brand')
      .flatMap(({ options }) =>
        options.filter((option) => option.toLowerCase().includes(input.toLowerCase()))
      )
      .slice(0, 5);

    const matchingOptions = matchingBrandOptions.length > 0 ? matchingBrandOptions : matchingOtherOptions;

    setSearchSuggestions(matchingOptions);
  }
};
  
  export const handleSearchInputChange = (e, setSearchKeywords, setSearchValue, setSearchSuggestions, propertyOptions) => {
    const inputValue = e.target.value;
  
    if (inputValue.trim() === '') {
      setSearchKeywords([]);
      setSearchValue('');
      setSearchSuggestions([]);
    } else {
      const keywords = inputValue.split(',').map((keyword) => keyword.trim());
      setSearchKeywords(keywords);
  
      setSearchValue(inputValue);
      updateSearchSuggestions(inputValue, propertyOptions, setSearchSuggestions);
    }
  };
  
  
  // export const handleSuggestionClick = (
  //   suggestion,
  //   selectedFilters,
  //   setSearchValue,
  //   setSearchSuggestions,
  //   setDisplayedSearchKeyword,
  //   setSearchKeywords,
  //   setDisplayedSearchKeywordsWithoutKey,
  //   data,
  //   setSelectedFilters,
  //   filterAndSortData
  // ) => {
  //   console.log(data);
  //   if (suggestion.includes(':')) {
  //     const [key, value] = suggestion.split(':');
  
  //     setSearchValue("");
  //     setSearchSuggestions([]);
  //     setDisplayedSearchKeyword((prevKeywords) => [...prevKeywords, ...[value]]);
  //   } else {
  //     setSearchValue("");
  //     setSearchSuggestions([]);
  
  //     const existingFilter = Object.entries(setSelectedFilters).find(
  //       ([filterKey]) => filterKey === suggestion.toLowerCase()
  //     );
  
  //     if (existingFilter) {
  //       const [filterKey, filterValues] = existingFilter;
  //       const newFilterValues = Array.from(new Set([...filterValues, suggestion]));
  
  //       setSelectedFilters((prevFilters) => ({
  //         ...prevFilters,
  //         [filterKey]: newFilterValues,
  //       }));
  //     } else {
  //       const matchingCar = data.find((car) => {
  //         const numericValue = parseInt(suggestion);
  //         return (
  //           car[suggestion.toLowerCase()] === suggestion ||
  //           (numericValue && car[suggestion.toLowerCase()] === numericValue)
  //         );
  //       });
  //       console.log(matchingCar);
  //       if (matchingCar) {
  //         setSelectedFilters((prevFilters) => ({
  //           ...prevFilters,
  //           [suggestion.toLowerCase()]: [matchingCar[suggestion.toLowerCase()]],
  //         }));
  //       }
  
  //       setSearchKeywords((prevKeywords) => [...new Set([...prevKeywords, suggestion])]);
  //       setDisplayedSearchKeywordsWithoutKey((prevKeywords) => [...new Set([...prevKeywords, suggestion])]);
  //     }
  
  //     filterAndSortData(); // Add this line to trigger the filtering
  //   }
  // };

  export const handleSuggestionClick = (suggestion, setSearchValue, setSearchSuggestions, setDisplayedSearchKeyword, setSearchKeywords, data, setSelectedFilters, filterAndSortData) => {
    if (suggestion.includes(':')) {
      const [key, value] = suggestion.split(':');
  
      setSearchValue('');
      setSearchSuggestions([]);
      setDisplayedSearchKeyword((prevKeywords) => [...prevKeywords, ...[value]]);
    } else {
      setSearchValue('');
      setSearchSuggestions([]);
      setDisplayedSearchKeyword((prevKeywords) => [...prevKeywords, suggestion]);
  
      const matchingCar = data.find((car) => {
        const numericValue = parseInt(suggestion);
        return (
          car[suggestion.toLowerCase()] === suggestion ||
          (numericValue && car[suggestion.toLowerCase()] === numericValue)
        );
      });
  
      if (matchingCar) {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          [suggestion.toLowerCase()]: [matchingCar[suggestion.toLowerCase()]],
        }));
      }
  
      setSearchKeywords((prevKeywords) => [...new Set([...prevKeywords, suggestion])]);
    }
  
    filterAndSortData();
  };
  