import { createRoot } from 'react-dom/client';
import App from './components/App';
import App2 from './components/App2';

import 'bootstrap/dist/css/bootstrap.min.css';

import { fetchData1,fetchData2,fetchData3} from "./utils/fetch";

function initializeApp(container, fetchFunction) {
 const root = createRoot(container);
 root.render(<App fetchFunction={fetchFunction} />);
}

function initializeAppSample(container, fetchFunction) {
  const root = createRoot(container);
  root.render(<App2 fetchFunction={fetchFunction} />);
}

document.addEventListener('DOMContentLoaded', () => {
 const container1 = document.getElementById('Luxcammy1');
 const container2 = document.getElementById('Luxcammy2');
 const container3 = document.getElementById('Luxcammy3');
 
 if (container1) {
    initializeApp(container1, fetchData1);
 }

 if (container2) {
    initializeApp(container2, fetchData1);
 }
  if (container3) {
    initializeAppSample(container3, fetchData1); 
  }

  
});

