
export const searchMatchesForCar = (car, displayedSearchKeyword) => {
    const carProperties = Object.keys(car).filter(
      (key) =>
        key !== 'Pictures' ||
        key !== 'PictureCount' ||
        key !== 'ModifiedDate' ||
        key !== 'CreatedDate' ||
        key !== 'Categories' ||
        key !== 'SpecificationId' ||
        key !== 'VehicleId' ||
        key !== 'VehicleSourceId'
    );
  
    return displayedSearchKeyword.every((keyword) => {
      return carProperties.some((key) => {
        if (key.toLowerCase() === 'equipmentlist' && Array.isArray(car[key])) {
          return car[key].some((item) => item.toLowerCase().includes(keyword.toLowerCase()));
        } else if (typeof car[key] === 'string') {
          const propertyValue = car[key].toLowerCase();
          return (
            propertyValue.includes(keyword.toLowerCase()) ||
            `${car.Make} ${car.Model}`.toLowerCase().includes(keyword.toLowerCase())
          );
        } else if (typeof car[key] === 'number') {
          const numericKeyword = parseFloat(keyword);
          return (
            !isNaN(numericKeyword) &&
            (car[key] === numericKeyword || `${car[key]}`.includes(`${numericKeyword}`))
          );
        }
        return false;
      });
    });
  };
  