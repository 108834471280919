
import { Modal, Offcanvas, Button, Stack, Row, Col,Badge } from "react-bootstrap";
import dealerSettings from "./setup"; // Importer dine indstillinger

import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";
import React, { useMemo } from "react";
import Slider from "rc-slider";
import {
    generateCheckboxFilter,
    generateCombinedCheckboxButtons,
} from "../utils/filtering";

import "../styles/filtering.css";
import { CustomAccordion } from "../utils/layout/accordion";
import { formatCurrency, formatNumber } from "../utils/basis";
import { RxReset } from "react-icons/rx";
const Filtering = ({
    onToggleOffcanvas,
    dealerId,
    data,
    selectedFilters,
    onFilterChange,
    filteredDataCount,
    appliedFilters,
    setAppliedFilters,
    loading,
}) => {
    if (loading) {
        return (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "10vh" }}>
            <l-dot-stream size="60" speed="2.5" color="black" text="Loading"></l-dot-stream>
          </div>
        );
    }
    const appliedFiltersCount = Object.entries(selectedFilters)
    .filter(([key, value]) => key !== 'price' && key !== 'mileage' && key !== 'year' && Array.isArray(value) && value.length > 0)
    .length;

    // console.log( selectedFilters);
    
    const handleOffcanvasToggle = () => {
        setIsOffcanvasOpen(true);
    };
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const settings = dealerSettings[dealerId] || { filterType: "modal" }; // Standard til 'modal'
    const filterType = settings.filterType;

    const uniqueMakes = useMemo(
        () => [...new Set(data.map((item) => item.Make))].sort(),
        [data]
    );
    const uniqueModels = useMemo(
        () => [...new Set(data.map((item) => item.Model))].sort(),
        [data]
    );
    const uniqueTypes = useMemo(
        () => [...new Set(data.map((item) => item.Type))].sort(),
        [data]
    );
    const uniqueGearTypes = useMemo(
        () => [...new Set(data.map((item) => item.GearType))].sort(),
        [data]
    );
    const uniquePriceTypes = useMemo(
        () => [...new Set(data.map((item) => item.PriceType))].sort(),
        [data]
    );
    const uniquePropellants = useMemo(
        () => [...new Set(data.map((item) => item.Propellant))].sort(),
        [data]
    );

    const uniqueEquipment = useMemo(
        () =>
          Object.fromEntries(
            Object.entries(
              data.flatMap((item) => item.EquipmentList).reduce((acc, equipmentList) => {
                Object.entries(equipmentList).forEach(([key, value]) => {
                  acc[key] = [...(acc[key] || []), ...value];
                });
                return acc;
              }, {})
            ).map(([key, value]) => [key, [...new Set(value)].sort()])
          ),
        [data]
      );
    uniqueMakes.sort();
    uniqueModels.sort();
    uniqueTypes.sort();
    uniqueGearTypes.sort();
    uniquePriceTypes.sort();
    uniquePropellants.sort();
   

    const [availableModels, setAvailableModels] = useState([]);
    const [progressBarLength, setProgressBarLength] = useState(0);

    useEffect(() => {
        setProgressBarLength((filteredDataCount / data.length) * 100);
    }, [filteredDataCount]);

    useEffect(() => {
        const selectedMake = selectedFilters.make;
        let allModels = [];

        if (selectedMake.length === 0) {
            allModels = [...new Set(data.map((item) => item.Model))];
        } else {
            allModels = [
                ...new Set(
                    data
                        .filter((item) => selectedMake.includes(item.Make))
                        .map((item) => item.Model)
                ),
            ];
        }

        setAvailableModels(allModels);
    }, [selectedFilters.make, data]);
    // console.log(selectedFilters["year"]);
    const years = [...new Set(data.map((item) => item.Year))];
    const mileages = data.map((item) => item.Mileage);
    const prices = data.map((item) => item.Price);

    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);
    const minMileage = Math.min(...mileages);
    const maxMileage = Math.max(...mileages);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    const handleCheckboxChange = (filterType, isChecked, value) => {
        let updatedFilters = { ...selectedFilters };

        if (isChecked) {
            updatedFilters[filterType] = [
                ...(updatedFilters[filterType] || []),
                value,
            ];
        } else {
            updatedFilters[filterType] = (
                updatedFilters[filterType] || []
            ).filter((val) => val !== value);
        }

        onFilterChange(filterType, updatedFilters[filterType]);

        // Update the appliedFilters state
        setAppliedFilters({
            ...appliedFilters,
            [filterType]: isChecked
                ? [...(appliedFilters[filterType] || []), value]
                : [],
        });
    };

    const filterCategories = {
        Type: uniqueTypes,
        Propellant: uniquePropellants,
        PriceType: uniquePriceTypes,
        GearType: uniqueGearTypes,
    };

    const [yearValues, setYearValues] = useState([
        minYear === Infinity ? selectedFilters["year"][0] : minYear,
        maxYear === -Infinity ? selectedFilters["year"][1] : maxYear,
    ]);
    const [mileageValues, setMileageValues] = useState([
        minMileage === Infinity ? selectedFilters["mileage"][0] : minMileage,
        maxMileage === -Infinity ? selectedFilters["mileage"][0] : maxMileage,
    ]);
    const [priceValues, setPriceValues] = useState([
        minPrice === Infinity ? selectedFilters["price"][0] : minPrice,
        maxPrice === -Infinity ? selectedFilters["price"][0] : maxPrice,
    ]);

    const handleResetFilters = () => {
        const resetFilters = {
            type: [],
            make: [],
            model: [],
            geartype: [],
            year: [minYear, maxYear],
            mileage: [minMileage, maxMileage],
            price: [minPrice, maxPrice],
            propellant: [],
            pricetype: [],
            EquipmentList: [],
        };

        Object.keys(resetFilters).forEach((filterType) => {
            onFilterChange(filterType, resetFilters[filterType]);
        });

        setYearValues([minYear, maxYear]);
        setMileageValues([minMileage, maxMileage]);
        setPriceValues([minPrice, maxPrice]);
        setAppliedFilters({});
    };

    const handleFilterCountClick = () => {
        onClose();
    };
    const handleFilterSelect = (filterType, values) => {
        onFilterChange(filterType, values);

        // Update the appliedFilters state
    };
    const [portalRoot, setPortalRoot] = useState(null);

    return (
        <>
            {/* <Button
                variant="outline-secondary"
                className="btn-custom-primary"
                onClick={() =>
                    filterType === "offcanvas"
                        ? handleOffcanvasToggle()
                        : setShowModal(true)
                }
            >
                Filtre
            </Button> */}
            <Button
            variant="outline-secondary"
            className="btn-custom-primary"
            onClick={() =>
                filterType === "offcanvas"
                    ? handleOffcanvasToggle()
                    : setShowModal(true)
            }
            style={{ display: 'flex', alignItems: 'center' }} // Tilføjer Flexbox-stil
        >
            Filtre{' '}
            {appliedFiltersCount > 0 && (
                <Badge className="btn-primary" pill variant="info" style={{ marginLeft: '5px' }}> {/* Tilføjer margin til at adskille bagde fra teksten */}
                    {appliedFiltersCount}
                </Badge>
            )}
   </Button>
            {filterType === "offcanvas" && (
                <Offcanvas
                    show={isOffcanvasOpen}
                    onHide={handleOffcanvasToggle}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Filtrer</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="filter-items">
                            <div className="row">
                                <div className="col-md-12">
                                    {generateCheckboxFilter(
                                        "make",
                                        uniqueMakes,
                                        selectedFilters,
                                        handleCheckboxChange,
                                        false,
                                        "Mærke"
                                    )}
                                </div>
                                <div className="col-md-12">
                                    {generateCheckboxFilter(
                                        "model",
                                        uniqueModels,
                                        selectedFilters,
                                        handleCheckboxChange,
                                        false,
                                        "Model"
                                    )}
                                </div>
                                <div className="col-md-12">
                                    {generateCheckboxFilter(
                                        "EquipmentList",
                                        uniqueEquipment,
                                        selectedFilters,
                                        handleCheckboxChange,
                                        false, // existing includeSearch parameter
                                        "Udstyr" //DanishName
                                    )}
                                </div>
                                <div></div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <CustomAccordion
                                        filterType="Type"
                                        renderContent={() =>
                                            generateCombinedCheckboxButtons(
                                                filterCategories,
                                                selectedFilters,
                                                handleCheckboxChange
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="range-item">
                                        <div className="Lux-h4">
                                            Årgang: {yearValues[0]} -{" "}
                                            {yearValues[1]}
                                        </div>
                                        <Slider
                                            range
                                            min={minYear}
                                            max={maxYear}
                                            value={yearValues}
                                            onChange={(values) => {
                                                setYearValues(values);
                                                handleFilterSelect(
                                                    "year",
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="range-item">
                                        <div className="Lux-h4">
                                            Pris:{" "}
                                            {formatCurrency(priceValues[0])} -{" "}
                                            {formatCurrency(priceValues[1])}
                                        </div>
                                        <Slider
                                            range
                                            min={minPrice}
                                            max={maxPrice}
                                            value={priceValues}
                                            onChange={(values) => {
                                                setPriceValues(values);
                                                handleFilterSelect(
                                                    "price",
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="range-item">
                                        <div className="Lux-h4">
                                            Kilometer:{" "}
                                            {formatNumber(mileageValues[0])} km.
                                            - {formatNumber(mileageValues[1])}{" "}
                                            km.
                                        </div>
                                        <Slider
                                            range
                                            min={minMileage}
                                            max={maxMileage}
                                            value={mileageValues}
                                            onChange={(values) => {
                                                setMileageValues(values);
                                                handleFilterSelect(
                                                    "mileage",
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    width: "80%",
                                    margin: "0 auto",
                                    borderTop:
                                        "1px solid rgba(136, 136, 136, 0.13)",
                                    paddingTop: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <button
                                    className="lux-button-secondary "
                                    onClick={handleResetFilters}
                                    style={{
                                        flexGrow: 1,
                                        padding: "10px", // Tilføjet for ensartet udseende
                                        border: "none",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <RxReset />
                                </button>
                                <Button
                                    className="lux-button-primary"
                                    onClick={setShowModal(false)}
                                    style={{
                                        flexGrow: 1,
                                        padding: "10px", // Tilføjet for ensartet udseende
                                        border: "none",
                                        borderRadius: "5px",
                                    }}
                                >
                                    Filtrér [{filteredDataCount}]
                                </Button>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            )}
            {filterType === "modal" && (
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Stack direction="horizontal" gap={2}>
                                <small>Filtering</small>
                                <Button
                                    Title="Nulstil"
                                    variant="outline-danger"
                                    onClick={handleResetFilters}
                                >
                                    <RxReset />
                                </Button>
                                <Button
                                    Title="Luk Modal og se blandt filtered biler"
                                    variant="outline-dark"
                                    onClick={() => setShowModal(false)}
                                >
                                    Total: {filteredDataCount}
                                </Button>
                            </Stack>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="filter-items">
                            <div className="row">
                                <div className="col-md-12">
                                    {generateCheckboxFilter(
                                        "make",
                                        uniqueMakes,
                                        selectedFilters,
                                        handleCheckboxChange,
                                        false,
                                        "Mærke"
                                    )}
                                </div>
                                <div className="col-md-12">
                                    {generateCheckboxFilter(
                                        "model",
                                        uniqueModels,
                                        selectedFilters,
                                        handleCheckboxChange,
                                        false,
                                        "Model"
                                    )}
                                </div>
                                <div className="col-md-12">
                                    {generateCheckboxFilter(
                                        "EquipmentList",
                                        Object.values(uniqueEquipment).flat(),
                                        selectedFilters,
                                        handleCheckboxChange,
                                        true, // existing includeSearch parameter
                                        "Udstyr", //DanishName
                                      true
                                    )}
                                </div>
                                <div></div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <CustomAccordion
                                        filterType="Type"
                                        renderContent={() =>
                                            generateCombinedCheckboxButtons(
                                                filterCategories,
                                                selectedFilters,
                                                handleCheckboxChange
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="range-item">
                                        <div className="Lux-h4">
                                            Årgang: {yearValues[0]} -{" "}
                                            {yearValues[1]}
                                        </div>
                                        <Slider
                                            range
                                            min={minYear}
                                            max={maxYear}
                                            value={yearValues}
                                            onChange={(values) => {
                                                setYearValues(values);
                                                handleFilterSelect(
                                                    "year",
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="range-item">
                                        <div className="Lux-h4">
                                            Pris:{" "}
                                            {formatCurrency(priceValues[0])} -{" "}
                                            {formatCurrency(priceValues[1])}
                                        </div>
                                        <Slider
                                            range
                                            min={minPrice}
                                            max={maxPrice}
                                            value={priceValues}
                                            onChange={(values) => {
                                                setPriceValues(values);
                                                handleFilterSelect(
                                                    "price",
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="range-item">
                                        <div className="Lux-h4">
                                            Kilometer:{" "}
                                            {formatNumber(mileageValues[0])} km.
                                            - {formatNumber(mileageValues[1])}{" "}
                                            km.
                                        </div>
                                        <Slider
                                            range
                                            min={minMileage}
                                            max={maxMileage}
                                            value={mileageValues}
                                            onChange={(values) => {
                                                setMileageValues(values);
                                                handleFilterSelect(
                                                    "mileage",
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Row className="justify-content-sm-center">
                            <Col md="auto">
                                <Button
                                    variant="outline-danger"
                                    onClick={handleResetFilters}
                                    className="mb-2 mr-md-2" // Tilføjer en margin til bunden og til højre på større skærme
                                    style={{ width: "100%" }} // Gør knappen til at fylde hele bredden på mindre skærme
                                >
                                    Nulstil
                                </Button>
                            </Col>
                            <Col md="auto">
                                <Button
                                    Title="Luk Modal og se siden med de filtreret biler"
                                    variant="primary"
                                    onClick={() => setShowModal(false)}
                                    style={{ width: "100%" }} // Gør knappen til at fylde hele bredden på mindre skærme
                                >
                                    Vis {filteredDataCount}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default Filtering;
