import React, { useState, useEffect } from "react";

import {
    CardGroup,
    Card,
    Row,
    Col,
    Container,
    Pagination,
    Stack,
    replace,
} from "react-bootstrap";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import { GrLinkNext } from "react-icons/gr";
import { motion } from "framer-motion";
import { Carousel } from "../components/Carousel";

import { formatCurrency, formatNumber, parseDate } from "../utils/basis";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRoad,
    faCar,
    faGasPump,
    faTachometerAlt,
    faPalette,
    faCog,
    faList,
    faDollarSign,
    faInfo,
} from "@fortawesome/free-solid-svg-icons";
const CarList2 = ({ cars }) => {
    const itemsPerPage = 20;
    const [activePage, setActivePage] = useState(1);
    const totalPages = Math.ceil(cars.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };
    useEffect(() => {
        setActivePage(1);
    }, [cars]);

    useEffect(() => {}, [activePage]);

    // Pagineringselementer
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === activePage}
                onClick={() => handlePageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    const shouldShowPagination = totalPages > 1;

    const indexOfLastCar = activePage * itemsPerPage;
    const indexOfFirstCar = indexOfLastCar - itemsPerPage;
    const currentCars = cars.slice(indexOfFirstCar, indexOfLastCar);

    function priceDetail(car) {
        return car.PriceType === "Leasing"
            ? "Månedlig leasing ydelse"
            : "Kontantpris";
    }

    function PriceBlock({ label, value, children }) {
        return (
            <div className="PriceBlockContainer">
                <div className="PriceLabel">{label}</div>
                <div className="PriceValueContainer">
                    <h3>{formatCurrency(value)}</h3>
                    {children}
                </div>
            </div>
        );
    }

    const handleCarClick = (carId) => {
        // Construct the target URL based on the carId
        const targetUrl = `${window.App.url}/biler#/car/${carId}`;
        // Navigate to the target URL
        window.location.href = targetUrl;
    };

    const handleSellCarClick = () => {
        // Bygger URL baseret på den miljøvariabel, der er eksponeret fra Laravel
        const sellCarUrl = `${window.App.url}/saelg-din-bil`;
        // Navigerer til URL'en
        window.location.href = sellCarUrl;
    };

    return (
        <Container>
         
            <Row xs={1} md={2} lg={2} xl={3} className="g-4">
                {currentCars.map((car) => (
                    <Col key={car.VehicleId}>
                        <motion.div initial="hidden" animate="visible">
                            <div
                                className="luxCarCard"
                                style={{ position: "relative" }}
                            >
                                {" "}
                                {/* Gør denne relativt positioneret */}
                                {!car.DeletedDate &&
                                    new Date() - new Date(car.CreatedDate) <
                                        14 * 24 * 3600 * 1000 && (
                                        <div className="StatusTagsContainer">
                                            <div className="status-tag">
                                                <span className="status-tag">
                                                    Nyhed
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                <div className="lux-carousel-container">
                                    {/* <Carousel
                                        className="lux-carousel"
                                        data={
                                            car.Pictures.length > 0
                                                ? [
                                                      car.Pictures[0].replace(
                                                          "class=S1600X1600",
                                                          "class=S640X640"
                                                      ),
                                                      car.Pictures[1].replace(
                                                          "class=S1600X1600",
                                                          "class=S640X640"
                                                      ),
                                                      car.Pictures[2].replace(
                                                          "class=S1600X1600",
                                                          "class=S640X640"
                                                      ),
                                                      car.Pictures[3].replace(
                                                          "class=S1600X1600",
                                                          "class=S640X640"
                                                      ),
                                                      car.Pictures[7].replace(
                                                          "class=S1600X1600",
                                                          "class=S640X640"
                                                      ),
                                                      car.Pictures[8].replace(
                                                          "class=S1600X1600",
                                                          "class=S640X640"
                                                      ),
                                                  ].map((picture, index) => ({
                                                      src: picture,
                                                      alt: `Car image ${
                                                          index + 1
                                                      }`,
                                                      className: "lux-slide",
                                                  }))
                                                : [
                                                      {
                                                          src: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg?w=1380",
                                                          alt: "No car image available",
                                                          className:
                                                              "lux-slide",
                                                      },
                                                  ]
                                        }
                                        carId={car.Id}
                                        car={car}
                                    />
                                     */}
                                    <Carousel
                                        className="lux-carousel"
                                        data={
                                            car.Pictures.length > 0
                                                ? car.Pictures.filter(
                                                      (pic, index) =>
                                                          [
                                                              0, 1, 2, 3, 7, 8,
                                                          ].includes(index)
                                                  ) // Først filtrerer vi for at sikre, at kun de ønskede indekser bliver inkluderet
                                                      .map((picture) =>
                                                          picture?.replace(
                                                              "class=S1600X1600",
                                                              "class=S640X640"
                                                          )
                                                      ) // Brug optional chaining til at kalde replace kun hvis picture ikke er undefined
                                                      .map(
                                                          (picture, index) => ({
                                                              src:
                                                                  picture ||
                                                                  "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg?w=1380", // Håndter undefined eller null ved at give en standardbillede URL
                                                              alt: `Car image ${
                                                                  index + 1
                                                              }`,
                                                              className:
                                                                  "lux-slide",
                                                          })
                                                      )
                                                : [
                                                      {
                                                          src: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg?w=1380",
                                                          alt: "No car image available",
                                                          className:
                                                              "lux-slide",
                                                      },
                                                  ]
                                        }
                                        useGlobalUrl={true}
                                        carId={car.VehicleId}
                                        car={car}
                                    />
                                </div>
                                <Card.Body
                                    onClick={() => handleCarClick(car.VehicleId)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Card.Title className="d-flex align-items-center justify-content-between">
                                        <h3>
                                            {car.Make} {car.Model}
                                        </h3>
                                        {/* <div className="ms-auto d-flex align-items-center">
                                               
                                            </div> */}

                                        {car.DeletedDate ? (
                                            <span className="bg-danger text-white px-2 py-1 rounded">
                                                Solgt
                                            </span>
                                        ) : (
                                            <>
                                                <div className="ms-auto d-flex ">
                                                    <GrLinkNext />
                                                </div>
                                            </>
                                        )}
                                    </Card.Title>
                                    <Card.Text className="text-muted variant-ellipsis">
                                        {car.Variant}

                                        {car.Variant}
                                    </Card.Text>
                                    <div className="scroll-container">
                                        {[
                                            car.Mileage && {
                                                icon: faRoad,
                                                label: `${formatNumber(
                                                    car.Mileage
                                                )} km`,
                                            },
                                            car.KmPerLiter && {
                                                icon: faTachometerAlt,
                                                label: `${car.KmPerLiter} Km/L`,
                                            },
                                            car.Propellant && {
                                                icon: faGasPump,
                                                label: car.Propellant,
                                            },

                                            car.Color && {
                                                icon: faPalette,
                                                label: car.Color,
                                            },
                                            car.GearType && {
                                                icon: faCog,
                                                label: car.GearType,
                                            },
                                            car.NewPrice && {
                                                icon: faDollarSign,
                                                label: `$${car.NewPrice}`,
                                            },
                                            car.EquipmentList && {
                                                icon: faList,
                                                label: `${Object.values(car.EquipmentList)
                                                  .flat()
                                                  .filter(Boolean).length} Udstyr`,
                                              },
                                        ]
                                            .filter(Boolean)
                                            .map((detail, index) => (
                                                <div
                                                    key={index}
                                                    className="detail-container"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={detail.icon}
                                                        className="detail-icon"
                                                    />
                                                    <p>{detail.label}</p>
                                                </div>
                                            ))}
                                    </div>
                                    {/* <Row className="justify-content-center mt-3">
                                            {[
                                                {
                                                    icon: "fa-calendar-alt",
                                                    label: car.Year,
                                                },
                                                {
                                                    icon: "fa-tachometer-alt",
                                                    label:
                                                        formatNumber(
                                                            car.Mileage
                                                        ) + "km",
                                                },
                                                {
                                                    icon: "fa-car",
                                                    label:
                                                        formatNumber(
                                                            car.KmPerLiter
                                                        ) + " km/L",
                                                },
                                                {
                                                    icon: "fa-gas-pump",
                                                    label: car.Propellant,
                                                },
                                            ].map((detail, index) => (
                                                <div
                                                    key={index}
                                                    className="col-sm-3 detail-container"
                                                >
                                                    <i
                                                        className={`fas ${detail.icon} fa-2x mb-2 detail-icon`}
                                                    ></i>
                                                    <p className="detail-text">
                                                        {detail.label}
                                                    </p>
                                                </div>
                                            ))}
                                        </Row> */}
                                </Card.Body>
                                {/* {!car.DeletedDate && ( */}
                                <Card.Footer
                                    style={{ minHeight: "100px" }}
                                    className="d-flex align-items-center justify-content-between"
                                >
                                    {car.DeletedDate ? (
                                        <Button
                                            variant="outline-secondary"
                                            onClick={handleSellCarClick}
                                            className="w-100" // Tilføjer w-100 klasse for at fylde hele bredden
                                        >
                                            Skal vi sælge din bil? Kom i Gang
                                            med Salget
                                        </Button>
                                    ) : (
                                        <>
                                            <PriceBlock
                                                label={priceDetail(car)}
                                                value={car.Price}
                                            />

                                            {car.PaymentPerMonth > 0 && (
                                                <div className="d-flex align-items-center">
                                                    <PriceBlock
                                                        label="Månedlig ydelse"
                                                        value={
                                                            car.PaymentPerMonth
                                                        }
                                                    />

                                                    <OverlayTrigger
                                                        trigger="click"
                                                        placement="left"
                                                        rootClose
                                                        overlay={
                                                            <Popover
                                                                id={`popover-${car.VehicleId}`}
                                                            >
                                                                <Popover.Header as="h3">
                                                                    Mærker
                                                                </Popover.Header>
                                                                <Popover.Body
                                                                    style={{
                                                                        fontSize:
                                                                            "1.25rem",
                                                                    }}
                                                                >
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: car.DefaultDutyText,
                                                                        }}
                                                                    />
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button
                                                            variant="outline-secondary"
                                                            size="sm"
                                                            className="ms-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faInfo}
                                                                size="xs"
                                                            />
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Card.Footer>
                                {/* )} */}
                            </div>
                        </motion.div>
                    </Col>
                ))}
            </Row>

            <div>
                {/* Conditional Rendering based on shouldShowPagination */}
                {shouldShowPagination && (
                    <Pagination
                        variant="outline-info"
                        size="sm"
                        className="justify-content-center custom-pagination"
                    >
                        {items}
                    </Pagination>
                )}
            </div>
            </Container>
    );
};

export default CarList2;
