import React, { useState, useEffect } from "react";

import {
    CardGroup,
    Card,
    Row,
    Col,
    Container,
    Pagination,
    Stack,
    replace,
} from "react-bootstrap";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import { GrLinkNext } from "react-icons/gr";
import { motion } from "framer-motion";
import { Carousel } from "../components/Carousel";
import { Link } from "react-router-dom";
import { formatCurrency, formatNumber, parseDate } from "../utils/basis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRoad,
    faCar,
    faGasPump,
    faTachometerAlt,
    faPalette,
    faCog,
    faCalendar,
    faList,
    faDollarSign,
    faInfo,
} from "@fortawesome/free-solid-svg-icons";

const CarList = ({ cars }) => {
    const itemsPerPage = 20;
    const [activePage, setActivePage] = useState(1);
    const totalPages = Math.ceil(cars.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    useEffect(() => {
        setActivePage(1);
    }, [cars]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activePage]);

    // Pagineringselementer
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === activePage}
                onClick={() => handlePageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    const indexOfLastCar = activePage * itemsPerPage;
    const indexOfFirstCar = indexOfLastCar - itemsPerPage;
    const currentCars = cars.slice(indexOfFirstCar, indexOfLastCar);

    function priceDetail(car) {
        return car.PriceType === "Leasing"
            ? "Månedlig leasing ydelse"
            : "Kontantpris";
    }

    function PriceBlock({ label, value, children }) {
        return (
            <div className="PriceBlockContainer">
                <div className="PriceLabel">{label}</div>
                <div className="PriceValueContainer">
                    <h3>{formatCurrency(value)}</h3>
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div>
            <Row xs={1} md={2} lg={2} xl={4} className="g-4">
                {currentCars.map((car) => (
                    <Col key={car.VehicleId}>
                        <motion.div initial="hidden" animate="visible">
                            <div
                                className="luxCarCard"
                                style={{ position: "relative" }}
                            >
                                {" "}
                                {/* Gør denne relativt positioneret */}
                                {!car.DeletedDate &&
                                    new Date() - new Date(car.CreatedDate) <
                                        14 * 24 * 3600 * 1000 && (
                                        <div className="StatusTagsContainer">
                                            <div className="status-tag">
                                                <span className="status-tag">
                                                    Nyhed
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                <div className="lux-carousel-container">
                                    <Carousel
                                        className="lux-carousel"
                                        data={
                                            car.Pictures.length > 0
                                                ? car.Pictures.filter(
                                                      (pic, index) =>
                                                          [
                                                              0, 1, 2, 3, 7, 8,
                                                          ].includes(index)
                                                  ) // Først filtrerer vi for at sikre, at kun de ønskede indekser bliver inkluderet
                                                      .map((picture) =>
                                                          picture?.replace(
                                                              "class=S1600X1600",
                                                              "class=S640X640"
                                                          )
                                                      ) // Brug optional chaining til at kalde replace kun hvis picture ikke er undefined
                                                      .map(
                                                          (picture, index) => ({
                                                              src:
                                                                  picture ||
                                                                  "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg?w=1380", // Håndter undefined eller null ved at give en standardbillede URL
                                                              alt: `Car image ${
                                                                  index + 1
                                                              }`,
                                                              className:
                                                                  "lux-slide",
                                                          })
                                                      )
                                                : [
                                                      {
                                                          src: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg?w=1380",
                                                          alt: "No car image available",
                                                          className:
                                                              "lux-slide",
                                                      },
                                                  ]
                                        }
                                        carId={car.VehicleId}
                                        car={car}
                                        useGlobalUrl={false}
                                    />
                                </div>
                                <Link
                                    to={
                                        car.DeletedDate ? "#" : `/car/${car.VehicleId}`
                                    }
                                    onClick={(e) =>
                                        car.DeletedDate && e.preventDefault()
                                    }
                                    state={{ car: car }}
                                    className={`styledLink ${
                                        car.DeletedDate ? "disabled-link" : ""
                                    }`}
                                >
                                    <Card.Body>
                                        <Card.Title className="d-flex align-items-center justify-content-between">
                                            <h3>
                                                {car.Make} {car.Model}
                                            </h3>
                                            {/* <div className="ms-auto d-flex align-items-center">
                                               
                                            </div> */}

                                            {car.DeletedDate ? (
                                                <span className="bg-danger text-white px-2 py-1 rounded">
                                                    Solgt
                                                </span>
                                            ) : (
                                                <>
                                                    <div className="ms-auto d-flex ">
                                                        <GrLinkNext />
                                                    </div>
                                                </>
                                            )}
                                        </Card.Title>
                                        <Card.Text className="text-muted variant-ellipsis">
                                            {car.Variant}
                                        </Card.Text>
                                        <div className="scroll-container">
                                            {[
                                       
                                                car.Mileage && {
                                                    icon: faRoad,
                                                    label: `${formatNumber(
                                                        car.Mileage
                                                    )} km`,
                                                },
                                                car.Year && {
                                                    icon: faCalendar,
                                                    label: `${
                                                        car.Year
                                                    }`,
                                                },
                                                car.GearType && {
                                                    icon: faCog,
                                                    label: car.GearType,
                                                },
                                                car.Propellant && {
                                                    icon: faGasPump,
                                                    label: car.Propellant,
                                                },

                                                car.KmPerLiter && {
                                                    icon: faTachometerAlt,
                                                    label: `${car.KmPerLiter} Km/L`,
                                                },
                                        
                                                car.Color && {
                                                    icon: faPalette,
                                                    label: car.Color,
                                                },
                                         
                                                // car.NewPrice && {
                                                //     icon: faDollarSign,
                                                //     label: `$${car.NewPrice}`,
                                                // },
                                                // car.EquipmentList && {
                                                //     icon: faList,
                                                //     label: `${Object.values(car.EquipmentList)
                                                //       .flat()
                                                //       .filter(Boolean).length} Udstyr`,
                                                //   },
                                            ]
                                                .filter(Boolean)
                                                .map((detail, index) => (
                                                    <div
                                                        key={index}
                                                        className="detail-container"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={detail.icon}
                                                            className="detail-icon"
                                                        />
                                                        <p>{detail.label}</p>
                                                    </div>
                                                ))}
                                        </div>

                                        {/* <Row className="justify-content-center mt-3">
                                            {[
                                                {
                                                    icon: "fa-calendar-alt",
                                                    label: car.Year,
                                                },
                                                {
                                                    icon: "fa-tachometer-alt",
                                                    label:
                                                        formatNumber(
                                                            car.Mileage
                                                        ) + "km",
                                                },
                                                {
                                                    icon: "fa-car",
                                                    label:
                                                        formatNumber(
                                                            car.KmPerLiter
                                                        ) + " km/L",
                                                },
                                                {
                                                    icon: "fa-gas-pump",
                                                    label: car.Propellant,
                                                },
                                            ].map((detail, index) => (
                                                <div
                                                    key={index}
                                                    className="col-sm-3 detail-container"
                                                >
                                                    <i
                                                        className={`fas ${detail.icon} fa-2x mb-2 detail-icon`}
                                                    ></i>
                                                    <p className="detail-text">
                                                        {detail.label}
                                                    </p>
                                                </div>
                                            ))}
                                        </Row> */}
                                    </Card.Body>
                                </Link>
                                {/* <Card.Footer>
                                    <h2>
                                        <PriceBlock
                                            label={priceDetail(car)}
                                            value={car.Price}
                                        />
                                    </h2>
                                </Card.Footer> */}
                                {!car.DeletedDate && (
                                    <Card.Footer
                                        style={{ minHeight: "100px" }}
                                        className="d-flex align-items-center justify-content-between"
                                    >
                                        {car.DeletedDate ? (
                                            <span></span>
                                        ) : (
                                            <>
                                                <PriceBlock
                                                    label={priceDetail(car)}
                                                    value={car.Price}
                                                />

                                                {car.PaymentPerMonth > 0 && (
                                                    <div className="d-flex align-items-center">
                                                        <PriceBlock
                                                            label="Månedlig ydelse"
                                                            value={
                                                                car.PaymentPerMonth
                                                            }
                                                        />

                                                        <OverlayTrigger
                                                            trigger="click"
                                                            placement="left"
                                                            rootClose
                                                            overlay={
                                                                <Popover
                                                                    id={`popover-${car.VehicleId}`}
                                                                >
                                                                    <Popover.Header as="h3">
                                                                        Mærker
                                                                    </Popover.Header>
                                                                    <Popover.Body
                                                                        style={{
                                                                            fontSize:
                                                                                "1.25rem",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: car.DefaultDutyText,
                                                                            }}
                                                                        />
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                            <Button
                                                                variant="outline-secondary"
                                                                size="sm"
                                                                className="ms-2"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faInfo
                                                                    }
                                                                    size="xs"
                                                                />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Card.Footer>
                                )}
                            </div>
                        </motion.div>
                    </Col>
                ))}
            </Row>
            <Row className="mt-3">
                <Pagination
                    variant="outline-info"
               
                    className="justify-content-center custom-pagination"
                >
                    {items}
                </Pagination>
            </Row>
        </div>
    );
};

export default CarList;
