import { parseDate } from "./basis";
import axios from 'axios';

const API_URL = `${window.App.url}/api/vehicles_data`;
// console.log(API_URL);

export const fetchData1 = async () => {
    try {
        const response = await axios.get(`${API_URL}`);
        if (!response.data) {
            throw new Error('No data found');
        }
        return response.data['Vehicles'];
    } catch (error) {
        throw error;
    }
};

export const fetchData2 = async () => {
    try {
        const response = await axios.get(`${API_URL}`);
        if (!response.data) {
            throw new Error('No data found');
        }
        const filteredData = response.data['Vehicles'].filter(vehicle => vehicle.PriceType === 'Leasing');
        return filteredData;
    } catch (error) {
        throw error;
    }
};

export const fetchData3 = async () => {
    try {
        const response = await axios.get(`${API_URL}`);
        if (!response.data) {
            throw new Error('No data found');
        }
        const sortedVehicles = response.data['Vehicles'].sort((a, b) => {
            const dateA = new Date(a.CreatedDate);
            const dateB = new Date(b.CreatedDate);
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;
            if (a.Make < b.Make) return -1;
            if (a.Make > b.Make) return 1;
            return 0;
        });
        const latestVehicles = sortedVehicles.slice(0, 6);
        return latestVehicles;
    } catch (error) {
        throw error;
    }
};

// export const fetchDataCustomer = async () => {
//     try {
//       const response = await fetch(API_URL);
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const jsonData = await response.json();
//       return jsonData['Vehicles'];
//     } catch (error) {
//       throw error;
//     }
//   };
